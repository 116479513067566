import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/core';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FileUploaderConfig } from './file-uploader.config';
import { CropComponent } from './components/crop/crop.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [FileUploaderComponent, CropComponent],
  imports: [CommonModule, ImageCropperModule, FormsModule, TranslateModule],
  exports: [FileUploaderComponent]
})

export class FileUploaderModule {

  static forRoot(config?: FileUploaderConfig): ModuleWithProviders<FileUploaderModule> {
    return {
      ngModule: FileUploaderModule,
      providers: [
        { provide: FileUploaderConfig, useValue: config }
      ]
    };
  }
}
