export class FileUploaderConfig {
    readonly basePath?: string;
    readonly backupImg?: string;
    readonly closeIcon?: string;
    readonly changeIcon?: string;
    readonly cropIcon?: string;
}

export interface IFileUploaderChanges {
    isDeleted?: boolean;
    isChanged?: boolean;
    value: File | FileList;
}

export interface ICropperSettings {
    aspectRatio: number;
    cropperMinWidth: number;
    cropperMinHeight: number;
    cropperMaxHeight: number;
    cropperMaxWidth: number;
    cropperStaticWidth: number;
    cropperStaticHeight: number;
}
