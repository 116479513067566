import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ELanguage } from '@multi-language-field/interfaces/languages';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { PreloaderService } from './core/services/preloader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'app';
  load = false;
  language: ELanguage = ELanguage.uk;
  constructor(
    private _preloader: PreloaderService,
    private _cd: ChangeDetectorRef,
    private _ts: TranslateService,
    private _config: PrimeNGConfig) {
  }

  ngOnInit(): void {
    this._preloader.loading
      .subscribe((isLoad: boolean) => {
        if (isLoad !== null) {
          this.load = isLoad;
        }
        this._cd.detectChanges();
      });
    this._initSiteLanguage();
  }

  private _initSiteLanguage(): void {
    const language = localStorage.getItem('spmLang') as ELanguage;
    if (!language || !Object.values(ELanguage).includes(language)) {
      localStorage.setItem('spmLang', this.language);
    } else {
      this.language = language as ELanguage;
    }

    this._ts.setDefaultLang(this.language);
    this._ts.get('primeng')
      .subscribe(res => {
        this._config.setTranslation(res);
        this._cd.detectChanges();
      });
  }

}
