import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { LanguageBarComponent } from './components/language-bar/language-bar.component';
import { ModeBarComponent } from './components/mode-bar/mode-bar.component';
import { MultiLanguageFieldComponent } from './components/multi-language-field/multi-language-field.component';
import { TranslatorComponent } from './components/translator/translator.component';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TranslatorService } from './services/translator.service';

@NgModule({
  declarations: [
    ModeBarComponent,
    LanguageBarComponent,
    MultiLanguageFieldComponent,
    TranslatorComponent
  ],
  imports: [
    CommonModule,
    AngularEditorModule,
    DropdownModule,
    FormsModule,
    AngularEditorModule,
    TranslateModule
  ],
  exports: [MultiLanguageFieldComponent, LanguageBarComponent],
  providers: [TranslatorService, MessageService]
})
export class ModuleMultiLanguageField { }
