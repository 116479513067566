import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../core/auth/auth.service';
import { Router } from '@angular/router';
import { ELanguage } from '@multi-language-field/interfaces/languages';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private _cookiesService: CookieService,
    private _authService: AuthService,
    private _router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let cloneReq = null;
    cloneReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.getToken()}`,
        lang: req.headers.get('Lang') || req.headers.get('lang') || this.getLang()
      },
      withCredentials: true
    });

    return next.handle(cloneReq)
      .pipe(
        catchError((err: any) => {
          if (err.status === 401) {
            this._authService.logout();
            this._router.navigate(['/login']);
          }
          return throwError(err);
        })
      );
  }

  getToken(): string {
    const cookieData = this._cookiesService.get('authorizationData');
    let cookieObj: any = {};
    let token = '';
    try {
      cookieObj = JSON.parse(cookieData);
      token = cookieObj.token;
    } catch (e) { }
    return token;
  }

  getLang(): ELanguage {
    return localStorage.getItem('spmLang') as ELanguage;
  }
}
