import { Observable } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { pluck } from 'rxjs/operators';
import { ITranslation, ITranslationItem } from 'src/app/module-multi-language-field/interfaces/translation';

@Injectable()
export class TranslatorService {
  backendRequest: HttpClient;
  private apiUrl = 'https://translation.googleapis.com/language/translate/v2';
  private apiKey = ''; // set google api key
  constructor(private httpBackend: HttpBackend) {
    this.backendRequest = new HttpClient(this.httpBackend);
  }

  translate(data: ITranslation): Observable<ITranslationItem[]> {
    return this.backendRequest.post(this.apiUrl, data, {
      params: { key: this.apiKey }
    }).pipe(pluck('data'), pluck('translations'));
  }
}
