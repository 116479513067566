import { MessageService } from 'primeng/api';
import { Injectable, Injector } from '@angular/core';
import { BackendValidationError, BaseErrorResponse } from 'src/app/models/types/response/base-error.interface';
import { FormGroup } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PreloaderService } from './preloader.service';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private _lifeMessageTime = 5000;
  constructor(
    private _injector: Injector,
    private _ms: MessageService,
    private _ps: PreloaderService,
    private _as: AuthService,
    private _r: Router
  ) { }

  handleError({ error, status }: HttpErrorResponse, form?: FormGroup): Observable<never> {
    this._ps.hide();
    const translateService = this._injector.get(TranslateService);
    if (status === 404) {
      this._ms.add({ severity: 'error', detail: translateService.instant('notAvailableMethodError'), life: this._lifeMessageTime });
    } else if (status === 401) {
      this._as.logout();
      this._r.navigate(['/login']);
    } else if (error.hasOwnProperty('InternalError')) {
      const e: BaseErrorResponse = error;
      this._ms.clear();
      this._ms.add({
        severity: 'error', detail: e.error || e.InternalError || translateService.instant('internalServerError'),
        life: this._lifeMessageTime
      });
    } else {
      if (typeof error === 'string') {
        this._ms.add({ severity: 'error', detail: error, life: this._lifeMessageTime });
      } else {
        this._catchBackendErrorWithFields(error, form);
      }
    }
    return EMPTY;
  }


  private _convertToCamelCase(str: string): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase()).replace(/\s+/g, '');
  }

  private _catchBackendErrorWithFields(error: any, form?: FormGroup): void {
    if (form) {
      const e: BackendValidationError = error;
      Object.keys(e).forEach(field => {
        form.get(this._convertToCamelCase(field)).setErrors({ backendErr: e[field][0] });
        form.markAllAsTouched();
      });
    } else {
      Object.keys(error).forEach(field => {
        this._ms.add({ severity: 'error', detail: error[field][0] });
      });
    }
  }
}
