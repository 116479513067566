import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { InputMaskModule } from 'primeng/inputmask';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TreeModule } from 'primeng/tree';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { TreeTableModule } from 'primeng/treetable';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { TabViewModule } from 'primeng/tabview';
import { SplitButtonModule } from 'primeng/splitbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ListboxModule } from 'primeng/listbox';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CalendarModule,
    ConfirmDialogModule,
    ToastModule,
    InputMaskModule,
    DropdownModule,
    InputTextModule,
    InputNumberModule,
    InputMaskModule,
    MultiSelectModule,
    ToastModule,
    DropdownModule,
    InputSwitchModule,
    MessagesModule,
    MessageModule,
    TreeModule,
    SelectButtonModule,
    TableModule,
    AccordionModule,
    CheckboxModule,
    TreeTableModule,
    ButtonModule,
    PaginatorModule,
    ToastModule,
    TabViewModule,
    SplitButtonModule,
    RadioButtonModule,
    ListboxModule,
  ],
  exports: [
    CalendarModule,
    ConfirmDialogModule,
    ToastModule,
    InputMaskModule,
    DropdownModule,
    InputTextModule,
    InputNumberModule,
    InputMaskModule,
    MultiSelectModule,
    ToastModule,
    DropdownModule,
    InputSwitchModule,
    MessagesModule,
    MessageModule,
    TreeModule,
    SelectButtonModule,
    TableModule,
    AccordionModule,
    CheckboxModule,
    TreeTableModule,
    ButtonModule,
    PaginatorModule,
    ToastModule,
    TabViewModule,
    SplitButtonModule,
    RadioButtonModule,
    ListboxModule,
  ]
})
export class PrimeNgLibraryModule { }
