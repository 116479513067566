import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _authUrl = environment.serverUrl;
  private _apiResetPassword = `${this._authUrl}/Account/ResetPassword`;
  private _apiLogin = `${this._authUrl}/Account/Login`;
  private _apiForgotPassword = `${this._authUrl}/Account/ForgotPassword`;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _cookieService: CookieService
  ) { }

  isLoggedIn(): boolean {
    const data = this._cookieService.get('authorizationData') && localStorage.getItem('user');
    return !!data;
  }

  login(login: string, password: string): Observable<string> {
    return this._http.post(this._apiLogin, { email: login, password })
      .pipe(
        tap((res: any) => {
          if (res) {
            this.writeTokenToCookie(res);
            localStorage.setItem('user', login);
            this._router.navigate(['/dashboard']);
          }
        })
      );
  }

  logout(): void {
    this._cookieService.delete('authorizationData');
    localStorage.removeItem('user');
  }

  postForgotPassword(email: string): Observable<any> {
    return this._http.post(this._apiForgotPassword, { email });
  }

  resetPassword(email: string, token: string, pass: string): Observable<any> {
    return this._http.post(this._apiResetPassword, { email, token, pass });
  }

  writeTokenToCookie(token: string): void {
    this._cookieService.set('authorizationData', JSON.stringify({ token }), null, null, null, null, null);
  }

}
