
export type TLanguage = 'ru' | 'uk' | 'en';

export interface ILanguage {
  ru: string;
  uk: string;
  en: string;
}

export interface ILanguageFile {
  ru: File;
  uk: File;
  en: File;
}

export enum ELanguage {
  uk = 'uk',
  ru = 'ru',
  en = 'en'
}

export function parseLanguageObject(): ILanguage {
  const language: any = {};
  Object.keys(ELanguage).forEach(el => language[el] = '');
  return language;
}
