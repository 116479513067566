<div class="windows8">
  <div class="wBall" id="wBall_1">
    <div class="wInnerBall"></div>
  </div>
  <div class="wBall" id="wBall_2">
    <div class="wInnerBall"></div>
  </div>
  <div class="wBall" id="wBall_3">
    <div class="wInnerBall"></div>
  </div>
  <div class="wBall" id="wBall_4">
    <div class="wInnerBall"></div>
  </div>
  <div class="wBall" id="wBall_5">
    <div class="wInnerBall"></div>
  </div>
</div>

<div class="timer" *ngIf="showTimer">
  <!--{{hoursDisplay ? hoursDisplay : '00'}}-->
  <span class="minutes">
    {{ minutesDisplay && minutesDisplay <= 59 ? minutesDisplay : "00" }}
  </span>
  :
  <span class="seconds">
    {{ secondsDisplay && secondsDisplay <= 59 ? secondsDisplay : "00" }}
  </span>
</div>

<div class="timer" *ngIf="showTitle">
  <!--{{hoursDisplay ? hoursDisplay : '00'}}-->
  <span class="minutes" style="font-size: 13px"> Loading... </span>
</div>
