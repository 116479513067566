import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {

  private _loading = new BehaviorSubject<boolean>(null);

  show(): void {
    this._loading.next(true);
  }
  hide(): void {
    this._loading.next(false);
  }

  get loading(): Observable<boolean> {
    return this._loading.asObservable();
  }

}
