
export type TLanguage = 'ru' | 'uk' | 'en';

export interface ILanguage {
  uk: string;
  ru: string;
  en: string;
}
export enum ELanguage {
  uk = 'uk',
  ru = 'ru',
  en = 'en'
}

export function parseLanguageObject(): ILanguage {
  const language: any = {};
  Object.keys(ELanguage).forEach(el => language[el] = '');
  return language;
}
