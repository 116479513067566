import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorValidationComponent } from './components/error-validation/error-validation.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { ModuleMultiLanguageField } from '@multi-language-field/module-multi-language-field.module';
import { LangFieldPipe } from '../pipes/lang-field/lang-field.pipe';
import { MessageService } from 'primeng/api';
import { PrimeNgLibraryModule } from './prime-ng/prime-ng-library.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BuildingStatusComponent } from './components/building-status/building-status.component';
import { SectionTopNavComponent } from './components/section-top-nav/section-top-nav.component';
import { RouterModule } from '@angular/router';
import { LangSwitcherComponent } from './components/lang-switcher/lang-switcher.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from '@directives/collapse';
import { AppCanvasAreaDrawDirective } from '@directives/canvas/ngx-canvas-area-draw.directive';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CounterComponent } from './components/counter/counter.component';
import { FileUploaderModule } from '@module-file-uploader/file-uploader.module';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDelModalComponent } from './components/confirm-del-modal/confirm-del-modal.component';
import { MapComponent } from './components/map/map.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { TranslateOptionsPipe } from '../pipes/translate-options/translate-options.pipe';
import { SanitizerModule } from '../pipes/sanitizer/sanitizer.module';
import { CacheUrlPipe } from '../pipes/cache-url/cache-url.pipe';


@NgModule({
  declarations: [
    ErrorValidationComponent,
    BuildingStatusComponent,
    PreloaderComponent,
    LangFieldPipe,
    SectionTopNavComponent,
    LangSwitcherComponent,
    AppCanvasAreaDrawDirective,
    CounterComponent,
    ConfirmDelModalComponent,
    MapComponent,
    PaginatorComponent,
    TranslateOptionsPipe,
    CacheUrlPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    PrimeNgLibraryModule,
    TranslateModule,
    InlineSVGModule.forRoot({
      baseUrl: '/assets/img/',
      bypassHttpClientInterceptorChain: true
    }),
    ModuleMultiLanguageField,
    ReactiveFormsModule,
    FormsModule,
    TooltipModule,
    ModalModule,
    AngularEditorModule,
    FileUploaderModule.forRoot({
      basePath: '/assets/downloads/',
      backupImg: 'default-img-blue.png',
      closeIcon: 'icon icon--close-white',
      changeIcon: 'icon icon--change',
      cropIcon: 'icon icon--crop'
    }),
    SanitizerModule
  ],
  exports: [
    // components
    ErrorValidationComponent,
    PreloaderComponent,
    BuildingStatusComponent,
    SectionTopNavComponent,
    LangSwitcherComponent,
    CounterComponent,
    ConfirmDelModalComponent,
    MapComponent,
    PaginatorComponent,

    // modules
    InlineSVGModule,
    FileUploaderModule,
    ModuleMultiLanguageField,
    PrimeNgLibraryModule,
    ReactiveFormsModule,
    FormsModule,
    TooltipModule,
    ModalModule,
    CollapseModule,
    AngularEditorModule,
    TranslateModule,
    SanitizerModule,

    // pipes
    LangFieldPipe,
    TranslateOptionsPipe,
    CacheUrlPipe,

    // directives
    AppCanvasAreaDrawDirective

  ],
  providers: [MessageService, BsModalRef]
})
export class ModuleShared { }
